import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Experience" />

    <div>
      <h1 className="text-4xl text-black mb-4">
        Exper<span className="text-blue-600">ience</span>
      </h1>

      <h2>Qualifications</h2>
      <ul className="list-disc ml-12">
        <li>
          Bachelor Arts: BA (Hons), Social Studies. Post Graduate Diploma: PG
          DIP,
        </li>
        <li>
          Professional Counselling and Psychotherapy. University Certificate
        </li>
        <li>
          Working therapeutically with children and adolescents. Neuro
          Linguistic
        </li>
        <li>
          Programming (NLP) Diploma BACP Accreditation Diploma Supervision
        </li>
        <li>
          Certificate Working with sexual abuse Certificate Working with couples
        </li>
        <li>I.E.M.T practitioner</li>
      </ul>

      <h2 className="text-2xl mt-8 mb-2">Experience </h2>
      <p>
        My week consists of seeing clients in private practice .  I also work as an affiliate counsellor for Employment Assistance Programmes (EAP’s) and am a supervisor for student and qualified counsellors.
      </p>
      <p>
        My previous experience included working at a local Women’s Centre where I saw people with a variety of issues including Anxiety, Depression, Bereavement and Abuse.
      </p>
      <p>
        I have volunteered for the local Multiple Sclerosis in a variety of roles, I set up a counselling service for those affected by MS. This service is for people with MS themselves, their family, friends or carers.
      </p>
      <p>
        As a Accredited member of the BACP I am required to keep my skills updated by attending extra training. My Continuing Professional Development (CPD) is ongoing and an essential activity whilst practising as a counsellor
      </p>
    </div>
  </Layout>
)

export default IndexPage
